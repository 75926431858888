import BaseService from '@src/services/BaseService'

class QuestionnaireFiltersService extends BaseService {
    constructor() {
        super('questionnaire-filters')
    }

    replicate(params) {
        return this.axios.post(`${this.getUrl()}/business-units/replicate`, params)
    }

    clean(id, params) {
        return this.axios.post(`${this.getUrl()}${id ? `/${id}` : ''}/clean`, params)
    }
}

export default new QuestionnaireFiltersService()
